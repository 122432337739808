import React from 'react';
import {ENDPOINT} from './Constants';
import { ApolloProvider, ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';

const uri = process.env.NODE_ENV === 'production' ? '/api/graphql/' : ENDPOINT;

const httpLink = new HttpLink({
   uri: uri, 
 });

/**
 * The HOC for handling localization by loading messages and setting up the provider.
 * @return {function(*): function(*): *}
 */
const withGraphql = (getAccessToken)=> Component => {
   const client = new ApolloClient({
      link: httpLink,
      cache: new InMemoryCache(),
      request: async operation => {
         let token;
         if (getAccessToken) {
            token = await getAccessToken();
         }
         operation.setContext({
            headers: {
               accesstoken: token || '',
            }
         });
      },
   });

   function WithGraphql(props) {
      return (
         <ApolloProvider client={client}>
            <Component {...props} />
         </ApolloProvider>
      );
   }

   return WithGraphql;
};

export default withGraphql;
