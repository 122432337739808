import MaUTable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel';
import {defer} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState, useEffect, useRef} from 'react'
import * as ReactDOM from 'react-dom';
import {useGlobalFilter, useSortBy, useTable,} from 'react-table'
import FHGTextField from '../../../components/TextField';
import Grid from '../Grid';
import TableSearchToolbar from './TableSearchToolbar';

// Create an editable cell renderer
const EditableCell = ({
   cell: {value: initialValue},
   row: {index},
   column: {id, isEditable},
   updateMyData, // This is a custom function that we supplied to our table instance
}) => {
   // We need to keep and update the state of the cell normally
   const [value, setValue] = React.useState(initialValue);
   const [showEdit, setShowEdit] = React.useState(false);

   const onChange = e => {
      setValue(e.target.value)
   };

   const handleClick = () => {
      setShowEdit(isEditable);
   };

   /**
    * Update the external data when the input is blurred.
    */
   const onBlur = () => {
      updateMyData(index, id, value);
      setShowEdit(false);
   };

   /**
    * If the initialValue is changed external, sync it up with our state.
    */
   React.useEffect(() => {
      setValue(initialValue);
      setShowEdit(false);
   }, [initialValue]);

   if (showEdit) {
      return (
         <FHGTextField
            withLabel={false}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            autoFocus
         />
      )
   } else {
      return (
         <div onDoubleClick={handleClick}>
            {value}
         </div>
      )
   }
};

EditableCell.propTypes = {
   cell: PropTypes.shape({
      value: PropTypes.any,
   }),
   row: PropTypes.shape({
      index: PropTypes.number.isRequired,
   }),
   column: PropTypes.shape({
      id: PropTypes.string.isRequired,
   }),
   updateMyData: PropTypes.func,
};

// Set our editable cell renderer as the default Cell renderer
const defaultColumn = {
   Cell: EditableCell,
};

/**
 * The table component that handles searching (filtering) and selection.
 *
 * Reviewed: 3/26/20
 *
 * @param titleKey The message key for the title.
 * @param columns The columns for the table.
 * @param data The data for the table.
 * @param updateMyData  Callback when a cell is edited.
 * @param skipPageReset Indicates that the page reset should be skipped.
 * @param onSelect Callback when an item is selected.
 * @param selectId The current selection item ID.
 * @param searchFilter The current search filter for external search.
 * @return {*}
 * @constructor
 */
export default function TableFHG({titleKey, columns, data, updateMyData, skipPageReset, onSelect, selectId, searchFilter, children, showSearchTextField = true}) {
   // Use the state and functions returned from useTable to build your UI
   const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: {globalFilter},
   } = useTable(
      {
         columns,
         data,
         defaultColumn,
         autoResetPage: !skipPageReset,
         // updateMyData isn't part of the API, but
         // anything we put into these options will
         // automatically be available on the instance.
         // That way we can call this function from our
         // cell renderer!
         updateMyData,
      },
      useGlobalFilter,
      useSortBy,
   );

   const [selectedIndex, setSelectedIndex] = useState(-1);
   const selectRef = useRef();

   /**
    * Handles keydown events for arrow up and arrow down. Moves the selection for the rows.
    */
   useEffect(() => {
      function handleKeyDown(event) {
         let newIndex;
         switch(event.code) {
            case "ArrowDown":
               newIndex = (selectedIndex + 1) % rows.length;
               break;
            case "ArrowUp":
               newIndex = (selectedIndex - 1 + rows.length) % rows.length;
               break;
            default:
            //Do nothing
         }
         if (rows && rows.length > newIndex && rows[newIndex].original) {
            onSelect && onSelect(rows[newIndex].original);
         }
      }

      document.addEventListener('keydown', handleKeyDown, false);

      // Cleanup the listener when this component is removed.
      return() => {
         document.removeEventListener('keydown', handleKeyDown, false);
      }
   }, [rows, selectedIndex, onSelect]);

   /**
    * Set the global filter from the search filter when the search filter changes.
    */
   useEffect(() => {
      if (searchFilter !== undefined) {
          setGlobalFilter(searchFilter);
      }
   }, [searchFilter, setGlobalFilter]);

   /**
    * Select the row when the selectId changes.
    */
   useEffect(() => {
      if (!!selectId) {
         const selectedIndex = rows.findIndex(row => {
            return row.original.id === selectId;
         });
         setSelectedIndex(selectedIndex);
         defer(() => {
            if (selectRef.current) {
               let element = ReactDOM.findDOMNode(selectRef.current);
               element.scrollIntoViewIfNeeded && element.scrollIntoViewIfNeeded();
            }
         });
      } else {
         setSelectedIndex(-1);
      }
   }, [rows, selectId]);

   /**
    * Select the row on click.
    * @param row The row clicked to be selected.
    * @return {function(...[*]=)}
    */
   const handleRowClick = (row) => () => {
      setSelectedIndex(row.index);
      onSelect && onSelect(row.original, false);
   };

   /**
    * Select the row on click.
    * @param row The row clicked to be selected.
    * @return {function(...[*]=)}
    */
   const handleRowDoubleClick = (row) => () => {
      setSelectedIndex(row.index);

      onSelect && onSelect(row.original, true);
   };

   return (
      <Grid name={'TableFHG Root Grid'} item container direction={'column'} fullWidth wrap={'nowrap'}>
         <TableSearchToolbar
            titleKey={titleKey}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            showSearchTextField={showSearchTextField}
            style={{backgroundColor: 'red'}}
         >
            {children}
         </TableSearchToolbar>
         <TableContainer >
            <MaUTable {...getTableProps()} stickyHeader>
               <TableHead>
                  {headerGroups.map((headerGroup) => (
                     <TableRow {...headerGroup.getHeaderGroupProps()} >
                        {headerGroup.headers.map(column => (
                           <TableCell
                              {...(column.id === 'selection'
                                 ? column.getHeaderProps()
                                 : column.getHeaderProps(column.getSortByToggleProps()))}
                              style={{backgroundColor: 'white', whiteSpace: "nowrap", padding: '6px 0px', fontSize: 18}}
                           >
                              {column.render('Header')}
                              {column.id !== 'selection' ? (
                                 <TableSortLabel
                                    active={column.isSorted}
                                    // react-table has a unsorted state which is not treated here
                                    direction={column.isSortedDesc ? 'desc' : 'asc'}
                                 />
                              ) : null}
                           </TableCell>
                        ))}
                     </TableRow>
                  ))}
               </TableHead>
               <TableBody>
                  {rows.map((row, i) => {
                     prepareRow(row);
                     const rowProps = row.getRowProps();
                     const { key, ...restRowProps } = rowProps; 
                     return (
                        <TableRow {...restRowProps} key={key} onClick={handleRowClick(row)} onDoubleClick={handleRowDoubleClick(row)} hover selected={i === selectedIndex} ref={i === selectedIndex ? selectRef : undefined}>
                           {row.cells.map(cell => {
                              return (
                                 <TableCell {...cell.getCellProps()}
                                    style={{whiteSpace: "nowrap", padding: '6px 0px', fontSize: 18}}
                                 >
                                    {cell.render('Cell')}
                                 </TableCell>
                              )
                           })}
                        </TableRow>
                     )
                  })}
               </TableBody>
            </MaUTable>
         </TableContainer>
      </Grid>
   )
}
