import { makeStyles } from '@mui/styles';
import React from 'react';
import * as PropTypes from 'prop-types';
import MuiGrid from '@mui/material/Grid/Grid';

const useGridStyles = makeStyles({
   gridStyle: props => {
      return ({
         overflow: props.overflow,
         height: props.fullHeight === true ? '100%' : (props.fullHeight === false ? 'unset' : undefined),
         width: props.fullWidth === true ? '100%' : (props.fullWidth === false ? 'unset' : undefined),
         flex: props.flex ? props.flex : (props.resizable ? '1 1' : (props.resizable === false ? '0 0 auto' : undefined)),
      })
   },
}, {name: 'gridStyles'});

/**
 * The Grid component that supports overflow as a property.
 *
 * Example:
 * <Grid overflow='auto' fullWidth resizable>...</Grid>
 *
 * Reviewed: 3/26/20
 */
// const Grid = (props) => {
//    const {overflow, fullWidth, flex, resizeable, children, className, resizable, fullHeight, ...otherProps } = props;
//    const classes = useGridStyles(props);

//    return (
//       <MuiGrid className={`${classes.gridStyle} ${className}`} {...otherProps}>
//          {children}
//       </MuiGrid>
//    );
// };

const Grid = ({
   overflow = 'hidden',
   fullWidth,
   flex,
   resizeable,
   children,
   className,
   resizable,
   fullHeight,
   ...otherProps
}) => {
   const classes = useGridStyles({ overflow, fullWidth, flex, resizeable, fullHeight, ...otherProps });

   return (
       <MuiGrid className={`${classes.gridStyle} ${className}`} {...otherProps}>
           {children}
       </MuiGrid>
   );
};

Grid.propTypes = {
   flex: PropTypes.string,          // The flex style property.
   resizable: PropTypes.bool,       // Indicates if the grid should use flex resizing (i.e. flex: '1 1')
   fullWidth: PropTypes.bool,       // Indicates if the grid should be 100%.
   fullHeight: PropTypes.bool,       // Indicates if the grid should be 100%.
   overflow: PropTypes.string,      // Key to message in the localization file.
   //Properties from Grid
   alignContent: PropTypes.any,
   alignItems: PropTypes.any,
   direction: PropTypes.any,
   justify: PropTypes.any,
   lg: PropTypes.any,
   md: PropTypes.any,
   sm: PropTypes.any,
   spacing: PropTypes.any,
   wrap: PropTypes.any,
   xs: PropTypes.any,
   zeroMinWidth: PropTypes.any,
   // ...MuiGrid.propTypes,            // Supports all the properties from Grid.
};

// Grid.defaultProps = {
//    overflow: 'hidden',
// };
Grid.displayName = 'Grid';

export default Grid;
