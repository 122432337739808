import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';

/**
 * The Typography component that supports intl. The default value is the child element. The id is the lookup value for
 * the locale file.
 *
 * Example:
 * <Typography id='path.suppliers' variant='button'>Suppliers</Typography>
 *
 * Reviewed: 3/26/20
 */
// const FHGTypography = ({id, children, values, ...otherProps}) => {

//    if (id) {
//       return (
//          <Typography id={id} {...otherProps} >
//             <FormattedMessage
//                id={id}
//                defaultMessage={children}
//                values={values}
//             />
//          </Typography>
//       );
//    } else {
//       return (
//          <Typography {...otherProps} >
//             {children}
//          </Typography>
//       );
//    }
// };

const FHGTypography = ({
   id,
   children,
   values,
   variant = 'inherit', // Default parameter added here
   ...otherProps
}) => {

   const inlineStyle = {
      display: 'inline'
   };

   if (id) {
      return (
         <Typography id={id} variant={variant} {...otherProps}>
            <FormattedMessage
               id={id}
               defaultMessage={children}
               values={values}
            />
         </Typography>
      );
   } else {
      return (
         <Typography variant={variant} {...otherProps}>
            {children}
         </Typography>
      );
   }
};

FHGTypography.propTypes = {
   id: PropTypes.string,                  // Key to message in the localization file.
   values: PropTypes.object,              // Values to use to fill parameters in the localized message.
   ...Typography.propTypes,               // Supports all the properties from Typography.
};

// FHGTypography.defaultProps = {
//    variant: 'inherit',
// };

FHGTypography.displayName = 'FHGTypography';

export default FHGTypography;
