import { Box } from '@mui/material';
import {lighten } from '@mui/material/styles';
import { makeStyles} from '@mui/styles';
import Toolbar from '@mui/material/Toolbar'
import PropTypes from 'prop-types'
import React from 'react'

import Typography from '../Typography';
import SearchFilter from './SearchFilter';

const useToolbarStyles = makeStyles(theme => ({
   root: {
      paddingLeft: '0px',
      paddingRight: '0px',
      width: '100%',
      flex: '0 0 auto',
   },
   highlight:
      theme.palette.type === 'light'
         ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
         }
         : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
         },
   title: {
      flex: '0 0 auto',
   },
}));

/**
 * Component to show the search (filter) for the table.
 *
 * Reviewed: 3/26/20
 *
 * @param (optional)numSelected The number of items selected.
 * @param (optional) titleKey The message key for the table.
 * @param setGlobalFilter The global filter callback to change the filtered rows in the table.
 * @param globalFilter The current global filter.
 * @return {*}
 * @constructor
 */
export default function TableSearchToolbar({numSelected = 0, titleKey, setGlobalFilter, globalFilter, children, showSearchTextField = true}) {
   const classes = useToolbarStyles();

   return (
      <Toolbar className={`${classes.root} ${numSelected > 0 ? classes.highlight : undefined}`} style={{paddingLeft:'0px', paddingRight:'0px'}}>
         <Box justifyContent={'space-between'} flexDirection={'row'} width={'100%'} display={'flex'} alignContent={'center'}>
         <Box flexDirection={'row'} display={'flex'} alignContent={'center'}>
               {titleKey && (
                  <Typography className={classes.title} variant='h5' id={titleKey}/>
               )}
               {children}
            </Box>
            <SearchFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} showSearchTextField={showSearchTextField}/>
         </Box>
      </Toolbar>
   )
};

TableSearchToolbar.propTypes = {
   setGlobalFilter: PropTypes.func.isRequired,
   globalFilter: PropTypes.string,
   titleKey: PropTypes.string,
   numSelected: PropTypes.number,
};
