import {useQuery} from '@apollo/client';
import { useTheme } from '@mui/material/styles';
import get from 'lodash/get';
import React, {Fragment, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import ItemDrawer from '../../components/ItemDrawer';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import Operator, {OPERATOR_QUERY} from './Operator';

/**
 * Drawer to navigate to the operators.
 *
 * Reviewed: 3/26/20
 */
export default function Operators() {
   const {id} = useParams();
   const {data, loading, error} = useQuery(OPERATOR_QUERY);
   const [operators, setOperators] = useState([]);
   const theme = useTheme();

   /**
    * Set the users from the data in the query.
    */
   useEffect(() => {
      if (!!data) {
         setOperators(get(data, 'operators') || []);
      }
   }, [data]);
   
   return (
      <Fragment>
         <DataLoadingAndErrors error={error} isLoading={loading}/>
         <ItemDrawer
            items={operators}
            prefixKey={'operators'}
            uri={'/operator'}
            allowCreate
            backgroundColor={theme.palette.primary.main}
            color={'white'}
         />
         <Operator key={`operatorView${id}`}/>
      </Fragment>
   );
}
