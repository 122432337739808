// import Button from '@mui/material/Button';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import Form from '../fhg/components/Form';
import Grid from '../fhg/components/Grid';
import Typography from '../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
   rootStyle: {
      maxHeight: 'fit-content !important',
      padding: theme.spacing(0, 2),
   },
   actionGridStyle: {
      marginTop: theme.spacing(1),
      padding: theme.spacing(0, 2),
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxHeight: '100%',
   },
}), {name: 'formCardStyles'});

/**
 * A Card that contains an input form. Contains the submit, optional cancel, and progress indication.
 *
 * Reviewed: 3/26/20
 */
export default function FormCard({onSubmit, onCancel, disabled, children}) {
   const classes = useStyles();

   /**
    * Handle the submit for the form. Stops propagation and prevents the default for the event so it doesn't add to the
    * URL.
    *
    * @param event The submit event.
    * @return {Promise<void>}
    */
   const handleSubmit = async (event) => {
      event && event.preventDefault();
      event && event.stopPropagation();
      try {
         onSubmit && await onSubmit();
      } catch (e) {
         console.log(e);
      }
   };

   /**
    * Handle the cancel of the form.
    *
    * @param event The cancel event.
    */
   const handleCancelClick = (event) => {
      event && event.stopPropagation();
      event && event.preventDefault();

      onCancel && onCancel(event);
   };

   return (
      <Form onSubmit={handleSubmit} className={classes.formStyle} id='form-card'>
         <Grid name={'FormCard-root'} item container className={classes.rootStyle} resizable={true} id='grid1'>
            <Grid name={'FormCard-content'} item container direction={'column'} resizable fullHeight wrap={'nowrap'}
                  style={{maxHeight: 'fit-content !important'}}>
               {children}
            </Grid>
         </Grid>
         <Grid name={'FormCard-actions'} container item className={classes.actionGridStyle} resizable={false}
               fullWidth direction={'row'} spacing={2} id='grid2'>
            <Grid item>
               <Button variant='contained' color='primary' type={'submit'} disabled={disabled}>
                  <Typography variant={'subtitle1'} id={'save.label'}/>
               </Button>
            </Grid>
            {onCancel && (
               <Grid item>
                  <Button color='default' onClick={handleCancelClick} disabled={disabled}>
                     <Typography variant={'subtitle1'} id={'cancel.button'}/>
                  </Button>
               </Grid>
            )}
         </Grid>
      </Form>
   );
}

FormCard.propTypes = {
   onSubmit: PropTypes.func.isRequired,// The callback when the form is submitted.
   onCancel: PropTypes.func,           // The callback when the form is canceled. If set the the cancel button is shown.
   disabled: PropTypes.bool,           // If true, disables the buttons.
   children: PropTypes.any,            //Children for the component.
};
