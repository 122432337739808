module.exports = {

   // General
   "refresh": 'Refresh',
   "app.title": 'Dashboard',
   "fetch.error": 'Could not load data. ({message})',
   "submit.error": 'Could not save data. ({message})',
   "filter.fetch.error": 'Could not load filter options. ({message})',
   "save.label": 'Save',
   'save.error': 'The changes could not be saved. ({message})',
   "cancel.button": 'Cancel',
   "close.button": 'Close',
   "ok.button": 'OK',
   'delete.button': 'Delete',
   'path.logout': 'Log Out',
   'leavePage': "You have made changes, which have not been saved. Do you want to discard changes?",
   'deletion.error': '"{name}" could not be deleted. ({message})',
   'delete.title': 'Delete',
   'noPermission.message': "You do not have permission to view this page. Contact your administrator to get permission.",
   'next.button': 'Next',
   'previous.button': 'Previous',
   'version': '{version}',

   //Sign in card
   'signIn.button.': "Sign In With Microsoft",
   'signIn.label': 'Sign In',

   'user.notOperatorAdmin.error': "User is not an operator admin. You are logged in as, {email}. Log in with a different account or contact your administrator to get permission.",
   'operator.notOperatorActive.error': "Operator is not active. Contact your administrator to get permission.",

   //App bar
   'application.title': 'Pressure Test Logger',
   'super.title': 'Super Admin Console',
   'operator.appbar.title': 'Admin Console {operator}',
   'appbar.title': '{appTitle} - {subTitle}',
   'search.placeholder': "Search…",

   //Operators
   'operators.title': 'Operators',
   'operators.button.label': 'Operators',
   'operators.newButton.label': 'New Operator',
   'operators.name.column': 'Name',
   'operators.email.column': 'Email',
   'operators.lastTest.column': 'Last Test',
   'operators.lastLogin.column': 'Last Login',
   'operators.active.column': 'Active',

   'operator.title': 'Operator',
   'operator.new.title': 'New Operator',
   'operator.noOperator.title': 'No Operator Available',
   'operator.openOperatorAdmin.title': 'Open Operator Admin',
   'operator.name.label': 'Name:',
   'operator.email.label': 'Email:',
   'operator.minimumAllowablePressure.label': 'Minimum Allowable Pressure (psi):',
   'operator.defaultStabilizationPeriod.label': 'Default Stabilization Period (minutes):',
   'operator.autoDeleteDays.label': 'Auto-Delete After Days:',
   'operator.gpsTimeout.label': 'GPS Timeout:',
   'operator.privacyPolicy.label': 'Privacy Policy / Terms of Use:',
   'operator.sendFailedTests.label': 'Send to FTP Failed Tests',
   'operator.sendEmail.label': 'Send Email',
   'operator.prohibitLowBatTest.label': 'Prohibit Low Battery Test',
   'operator.emailRecipient.label': 'Email Recipient',
   'operator.emailType.label': 'Email Type',
   'operator.maximumAllowablePressure.label': 'Maximum Allowable Pressure (psi):',
   'operator.maximumAllowablePressureDrop.label': 'Maximum Pressure Drop (psi):',
   'operator.active.label': 'Active',
   'operator.noOperator.label': 'No Operator Selected',
   'operator.testLength.label': 'Test Durations',
   'operator.testLengthEdit.title': 'Edit Test Duration',
   'operator.minimumPipeDiameter.column': 'Max. Pipe Diameter',
   'operator.minimumPipeLength.column': 'Min. Footage',
   'operator.testLength.column': 'Test Duration',
   'operator.testType.column': 'Test Type',
   'operator.addTestLength.button': 'Add',
   'operator.editTestLength.button': 'Edit',
   'operator.deleteTestLength.button': 'Delete',
   'operator.FieldLabel01.label': 'Field Name 1',
   'operator.FieldLabel02.label': 'Field Name 2',
   'operator.FieldLabel03.label': 'Field Name 3',
   'operator.FieldLabel04.label': 'Field Name 4',
   'operator.FieldLabel05.label': 'Field Name 5',
   'operator.FieldLabel06.label': 'Field Name 6',
   'operator.singleSegmentOnly.label' : 'Single Segment Only',
   'operator.testEntireLog.label' : 'Analyze Entire Dataset',
   'operator.tesType.column' : 'Test Type',
   'operator.systemPressure.column': 'System Pressures',
   'operator.testType.label': 'Test Types',
   'operator.addTestType.button': 'Add',
   'operator.editTestType.button': 'Edit',
   'operator.deleteTestType.button': 'Delete',
   'operator.testTypeEdit.title' : 'Test Type',
   'operator.systemPressureEdit.title' : 'System Pressure',
   'operator.systemPressures.label' :  'System Pressures',
   'operator.testSystemPressure.column': 'System Pressure',
   'operator.pressureSpecs.label': 'Pressure Specs',
   // Users
   'users.title': "Users",
   'users.addUser.button': "Add User",
   'users.addMultiple.button': "Add Multiple Users",

   'user.name.label': "Name:",
   'user.email.label': "Email:",
   'user.operatorAdmin.label': "Operator Admin",
   'user.active.label': "Active",
   'user.emailList.label': 'Email List:',
   'user.multipleUsers.title': 'Add Multiple Users',
   'user.multipleUsers.subtitle': 'Enter email addresses separated by commas',
   'user.newUser.title': 'New User',
   'user.user.title': 'User',
   'user.noUser.title': 'No User Selected',
   'user.invalidUser.title': 'Invalid User',
   'user.active.users' : 'Active users'
};
