import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import gql from 'graphql-tag';
import { clone } from 'lodash';
import { isString } from 'lodash';
import { findIndex } from 'lodash';
import uniqueId from 'lodash/uniqueId';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import React from 'react';
import ItemTable from '../../components/ItemTable';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import FHGTypography from '../../fhg/components/Typography';
import { removeOne } from '../../fhg/utils/Utils';
import TestLengthEdit from './TestLengthEdit';

const TEST_TYPE_INFO = gql`
  fragment testTypeInfo on TestType {
    id
    testType
    operatorId
  }
`;

const TEST_PARAMETER_FRAGMENT = gql`
   ${TEST_TYPE_INFO}
   fragment testParameterInfo on TestParameter {
      id
      minimumPipeDiameter
      minimumPipeLength
      operatorId
      testLength
      testType {
         ...testTypeInfo
      }
   }
`;

const TEST_PARAMETER_ALL_WHERE_QUERY = gql`
   query getTestParameterAllWhere($operatorId: [Int] ) {
      testParameters:testParameter_AllWhere(testParameterSearch: {operatorId: $operatorId}) {
         ...testParameterInfo
      }
   }
   ${TEST_PARAMETER_FRAGMENT}
`;

const TEST_TYPES_BY_OPERATOR_QUERY = gql`
   query getTestTypesByOperator($operatorId: [Int] ) {
      testTypes:testType_AllWhere(testTypeSearch: {operatorId: $operatorId}) {
         ...testTypeInfo
      }
   }
   ${TEST_TYPE_INFO}
`;

const TEST_PARAMETER_CREATE = gql`
   mutation testParameterCreate($minimumPipeDiameter: Float, $minimumPipeLength: Int, $operatorId: Int!, $testLength: Int, $testTypeId: Int!)
   {
      testParameter: testParameter_Create(testParameter: {minimumPipeDiameter: $minimumPipeDiameter, minimumPipeLength: $minimumPipeLength, operatorId: $operatorId, testLength: $testLength, testTypeId: $testTypeId}) {
         ...testParameterInfo
      }
   }
   ${TEST_PARAMETER_FRAGMENT}
`;

const TEST_PARAMETER_UPDATE = gql`
   mutation testParameterUpdate($id: Int!, $minimumPipeDiameter: Float, $minimumPipeLength: Int, $operatorId: Int!, $testLength: Int, $testTypeId: Int!)
   {
      testParameter: testParameter_Update(testParameterId: $id, testParameter: {minimumPipeDiameter: $minimumPipeDiameter, minimumPipeLength: $minimumPipeLength, operatorId: $operatorId, testLength: $testLength, testTypeId: $testTypeId}) {
         ...testParameterInfo
      }
   }
   ${TEST_PARAMETER_FRAGMENT}
`;

const TEST_PARAMETER_DELETE = gql`
   mutation testParameterDelete($id: Int!) {
      testParameter_Delete(testParameterId: $id)
   }
`;

const formatMinimumPipeDiameter = (value) => {
   if (value === undefined || value === null) return '';
   const num = parseFloat(value);
   return num % 1 === 0 ? num.toFixed(0) : num.toFixed(2);
};

const TestLengthTable = forwardRef(
   function TestLengthTable({ operatorId, onChange }, ref) {
      const [testLengthId, setTestLengthId] = useState();
      const [testLengthValue, setTestLength] = useState();
      const [deletedTestLengths, setDeletedTestLengths] = useState([]);

      const { data, loading, error } = useQuery(TEST_PARAMETER_ALL_WHERE_QUERY, { variables: { operatorId }, skip: !operatorId });

      const { data: dataTestTypes, loadingTestTypes, errorTestTypes } = useQuery(TEST_TYPES_BY_OPERATOR_QUERY, { variables: { operatorId } });

      const [testParameterCreate] = useMutationFHG(TEST_PARAMETER_CREATE, {
         refetchQueries: () => ([{ query: TEST_PARAMETER_ALL_WHERE_QUERY, variables: { operatorId } }])
      });
      const [testParameterUpdate] = useMutationFHG(TEST_PARAMETER_UPDATE, {
         refetchQueries: () => ([{ query: TEST_PARAMETER_ALL_WHERE_QUERY, variables: { operatorId } }])
      });


      const [testParameterDelete] = useMutationFHG(TEST_PARAMETER_DELETE, {
         refetchQueries: () => ([{ query: TEST_PARAMETER_ALL_WHERE_QUERY, variables: { operatorId } }])
      });

      const [testLengths, setTestLengths] = useState();
      const [testTypes, setTestTypes] = useState();

      const [showEdit, setShowEdit] = useState(false);
      const [isChanged, setIsChanged] = useState(false);

      useImperativeHandle(ref, () => ({
         async submit() {
            if (isChanged) {
               for (var testLength of testLengths) {
                  let mutate = isString(testLength.id) ? testParameterCreate : testParameterUpdate;

                  if (!Object.isExtensible(testLength)) {
                     testLength = { ...testLength };
                  }

                  if (!testLength.hasOwnProperty('testTypeId') && testLength.hasOwnProperty('testType')) {
                     testLength['testTypeId'] = testLength.testType.id;
                  }
                  
                  const variables = {
                     ...testLength,
                     minimumPipeDiameter: testLength.minimumPipeDiameter !== undefined 
                         ? parseFloat(testLength.minimumPipeDiameter) 
                         : null,
                     minimumPipeLength: testLength.minimumPipeLength !== undefined 
                         ? parseInt(testLength.minimumPipeLength, 10) 
                         : null,
                     testLength: testLength.testLength !== undefined 
                         ? parseInt(testLength.testLength, 10) 
                         : null,
                 };
                  
                  await mutate({ variables });
               }
               for (const deletedTestLength of deletedTestLengths) {
                  await testParameterDelete({ variables: { id: deletedTestLength.id } })
               }
               setIsChanged(false);
            }
         },
      }), [testLengths, deletedTestLengths, isChanged, testParameterCreate, testParameterUpdate, testParameterDelete]);

      useEffect(() => {
         if (dataTestTypes?.testTypes) {
            setTestTypes(dataTestTypes?.testTypes);
         }

         if (data?.testParameters) {
            //setTestLengths(data?.testParameters);
            if (data?.testParameters) {
               const sortedTestParameters = [...data.testParameters].sort((a, b) => {
                   return a.minimumPipeDiameter - b.minimumPipeDiameter;
               });
               setTestLengths(sortedTestParameters);
           }
         }
      }, [data, dataTestTypes]);

      /**
       * Adds a test length to the list.
       */
      const handleAddTestLength = () => {
         const testLength = { id: uniqueId('new'), operatorId };
         setTestLength(testLength);
         setShowEdit(true);
         setIsChanged(true);
         onChange && onChange();
      };

      /**
       * Edit a test length.
       */
      const handleEditTestLength = () => {
         setShowEdit(true);
      };

      /**
       * Delete the test length.
       */
      const handleDeleteTestLength = () => {
         setIsChanged(true);
         if (!isString(testLengthValue.id)) {
            const cacheDeleted = clone(deletedTestLengths);
            cacheDeleted.push(testLengthValue);
            setDeletedTestLengths(cacheDeleted);
         }

         const index = findIndex(testLengths, { id: testLengthValue.id });

         if (index >= 0) {
            const cache = [...testLengths];
            removeOne(cache, index);
            setTestLengths(cache);
            onChange && onChange();
         } else {
            console.log('Could not find test length to delete', testLengthValue);
         }
      }

      /**
       * Handle selecting an test length from the table.
       * @param testLength The testLength selected
       * @param isDoubleClick Indicates if the selection was a double click.
       */
      const handleSelect = (testLength, isDoubleClick = false) => {
         setTestLength(testLength);
         setTestLengthId(testLength?.id);
         setShowEdit(isDoubleClick);
      };

      /**
       * Get the columns for the test length table.
       */
      const columns = useMemo(
         () => [
            {
               Header: <FHGTypography id={'operator.minimumPipeDiameter.column'} />,
               accessor: 'minimumPipeDiameter',
               Cell: ({value}) => formatMinimumPipeDiameter(value),
            }, {
               Header: <FHGTypography id={'operator.minimumPipeLength.column'} />,
               accessor: 'minimumPipeLength',
            }, {
               Header: <FHGTypography id={'operator.testLength.column'} />,
               accessor: 'testLength',
            },
            {
               Header: <FHGTypography id={'operator.testType.column'} />,
               accessor: 'testType.testType',
            },
         ], []
      );

      /**
       * When the user submits changes to the selected testLength, update the list and hide the dialog.
       * @type {(function(*): void)|*}
       */
      const handleSubmit = useCallback((testLength) => {
         const cache = [...testLengths]; // Create a shallow copy of the array
         const index = findIndex(cache, { id: testLength.id });

         if (index < 0) {
            // If the item doesn't exist, add it
            setTestLengths([...cache, testLength]);
         } else {
            // If the item exists, update it
            const updatedCache = [...cache]; // Create a new copy for updates
            updatedCache[index] = testLength; // Update the specific index
            setTestLengths(updatedCache); // Set the new array to state
         }
         setTestLengthId(testLengthValue?.id);
         setTestLength(testLength);
         setShowEdit(false);
         setIsChanged(true);
         onChange && onChange();
      }, [testLengthValue, testLengths]);

      /**
       * On close, close the edit dialog.
       */
      const handleClose = () => {
         setShowEdit(false);
      };

      return (
         <>
            <DataLoadingAndErrors error={error} isLoading={loading} />
            <ItemTable titleKey={'operator.testLength.label'} columns={columns} items={testLengths}
               onSelect={handleSelect}
               selectId={testLengthId}>
               <Button color='primary' onClick={handleAddTestLength} style={{ marginLeft: 8 }}>
                  <FHGTypography noWrap id={'operator.addTestLength.button'} />
               </Button>
               <Button color='primary' onClick={handleEditTestLength} style={{ marginLeft: 8 }} disabled={!testLengthId}>
                  <FHGTypography noWrap id={'operator.editTestLength.button'} disabled={!testLengthId} />
               </Button>
               <Button color='primary' onClick={handleDeleteTestLength} style={{ marginLeft: 8 }} disabled={!testLengthId}>
                  <FHGTypography noWrap id={'operator.deleteTestLength.button'} disabled={!testLengthId} />
               </Button>
            </ItemTable>
            {showEdit && (
               <TestLengthEdit testLengthValue={testLengthValue} testTypes={testTypes} onSubmit={handleSubmit} onClose={handleClose} />
            )}
         </>
      );
   });

export default TestLengthTable;
