import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, createHttpLink, ApolloLink } from '@apollo/client';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import config from './Config';
import { ENDPOINT } from './Constants';
import './index.css';
import App, { userAgentApplicationState } from './pages/App';
import * as serviceWorker from './serviceWorker';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Observable } from 'apollo-link';


const httpLink = createHttpLink({
  uri: ENDPOINT, // Replace with your GraphQL endpoint
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const currentPath = window.location.pathname;
  console.log("OnError " + currentPath);
  // Avoid redirect loop by checking if the user is already on the login page
  if (currentPath === '/login') {
    return;
  }

  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path, extensions }) => {
      if (extensions?.code === 'UNAUTHENTICATED') {
        console.log('GraphQL 401 Error:', message);
        // Handle unauthorized error (401)
        // Redirect to login or show error message
        localStorage.removeItem('accessToken');
        // window.location.href = '/login'; // Example: Redirect to login page
      }
    });
  }

  if (networkError) {
    console.log('Network Error:', networkError);

    if (networkError.statusCode === 401) {
      // Handle 401 Unauthorized response from the server
      console.log('Network 401 Error: Unauthorized');
      localStorage.removeItem('accessToken');
      if (currentPath !== '/login') {
        // window.location.href = '/login'; // Redirect to login
      }
      // Redirect to login or show error message
      // window.location.href = '/login'; // Example: Redirect to login page
    }
  }
});

// Middleware to set the Authorization header
const authLink = setContext((_, { headers }) => {
  // Get the token from localStorage (or any other storage)
  const token = localStorage.getItem('accessToken');
  // Return the headers with the Authorization token

  if (!token) {
    console.log('No token available. Skipping GraphQL request.');
    // return null; // Skip the request
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});


const link = ApolloLink.from([errorLink, authLink.concat(httpLink)]);

// Apollo Client setup with middleware link
const client = new ApolloClient({
  //link: authLink.concat(httpLink),
  link,
  cache: new InMemoryCache(),
});

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
  // eslint-disable-next-line
  String.prototype.format = function (values) {
    return this.replace(/{(\w+)}/g, function (match, key) {
      return typeof values[key] !== 'undefined' ? values[key] : match;
    });
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ApolloProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
