import { useCallback } from 'react';
import React, { useState } from 'react';
import FHGTextField from '../../components/TextField';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';

/**
 * Component to edit a single test type.
 *
 * @param testTypeValue The test type to edit.
 * @param onSubmit Submit the changes to the test type.
 * @param onClose Close the edit dialog.
 * @return {JSX.Element}
 * @constructor
 */
export default function TestTypeEdit({ testTypeValue: testTypeValue, onSubmit, onClose }) {

   const [editValues, handleChange, { isChanged, getValue, defaultValues }] = useEditData(testTypeValue);

   /**
    * Submit the changes to the test type.
    * @type {(function(*): void)|*}
    */
   const handleSubmit = useCallback((event) => {

      console.log("handleSubmit " + JSON.stringify(editValues));

      if (event) {
         event.preventDefault();
         event.stopPropagation();
      }

      if (isChanged) {
         onSubmit && onSubmit({ ...defaultValues, ...editValues });
      }
   }, [editValues, defaultValues, isChanged, onSubmit]);

   return (
      <ModalDialog open={true} titleKey={'operator.testTypeEdit.title'} onSubmit={(event) => handleSubmit(event)} maxWidth={'sm'}
         fullWidth onClose={onClose}>
         <FHGTextField name='testType' type='text' autoFocus label={'Test Type'}
            style={{ marginBottom: 8 }}
            onChange={handleChange} value={getValue('testType')} required />
      </ModalDialog>
   );
}
