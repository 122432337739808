import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import gql from 'graphql-tag';
import { clone } from 'lodash';
import { isString } from 'lodash';
import { findIndex } from 'lodash';
import uniqueId from 'lodash/uniqueId';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import React from 'react';
import ItemTable from '../../components/ItemTable';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import FHGTypography from '../../fhg/components/Typography';
import { removeOne } from '../../fhg/utils/Utils';
import TestTypeEdit from './TestTypeEdit';

const TEST_TYPE_INFO_FRAGMENT = gql`
  fragment testTypeInfo on TestType {
    id
    testType
    operatorId
  }
`;

const TEST_TYPES_ALL_WHERE_QUERY = gql`
   query getTestTypesAllWhere($operatorId: [Int] ) {
      testTypes:testType_AllWhere(testTypeSearch: {operatorId: $operatorId}) {
         ...testTypeInfo
      }
   }
   ${TEST_TYPE_INFO_FRAGMENT}
`;

const TEST_TYPE_FRAGMENT = gql`
   fragment testTypeInfo on TestType {
      id
      testType
      operatorId
   }
`;

const TEST_TYPE_CREATE = gql`
   mutation testTypeCreate($testType: String!, $operatorId: Int!)
   {
      testType: testType_Create(testType: {testType: $testType, operatorId: $operatorId}) {
         ...testTypeInfo
      }
   }
   ${TEST_TYPE_FRAGMENT}
`;

const TEST_TYPE_UPDATE = gql`
   mutation testTypeUpdate($id: Int!, $operatorId: Int!, $testType: String)
   {
      testType: testType_Update(testTypeId: $id, testType: {operatorId: $operatorId, testType: $testType}) {
         ...testTypeInfo
      }
   }
   ${TEST_TYPE_FRAGMENT}
`;

const TEST_TYPE_DELETE = gql`
   mutation testTypeDelete($id: Int!) {
      testType_Delete(testTypeId: $id)
   }
`;

const TestTypeTable = forwardRef(
   function TestTypeTable({ operatorId, onChange }, ref) {
      const [testTypeId, setTestTypeId] = useState();
      const [testTypeValue, setTestType] = useState();
      const [deletedTestTypes, setDeletedTestTypes] = useState([]);

      const { data, loading, error } = useQuery(TEST_TYPES_ALL_WHERE_QUERY, { variables: { operatorId } });

      const [testTypeCreate] = useMutationFHG(TEST_TYPE_CREATE, {
         refetchQueries: () => ([{ query: TEST_TYPES_ALL_WHERE_QUERY, variables: { operatorId } }])
      });
      const [testTypeUpdate] = useMutationFHG(TEST_TYPE_UPDATE, {
         refetchQueries: () => ([{ query: TEST_TYPES_ALL_WHERE_QUERY, variables: { operatorId } }])
      });

      const [testTypeDelete] = useMutationFHG(TEST_TYPE_DELETE, {
         refetchQueries: () => ([{ query: TEST_TYPES_ALL_WHERE_QUERY, variables: { operatorId } }])
      });

      const [testTypes, setTestTypes] = useState();

      const [showEdit, setShowEdit] = useState(false);
      const [isChanged, setIsChanged] = useState(false);

      useImperativeHandle(ref, () => ({
         async submit() {
            if (isChanged) {
               for (const testType of testTypes) {
                  let mutate = isString(testType.id) ? testTypeCreate : testTypeUpdate;
                  await mutate({ variables: { ...testType } });
               }
               for (const deletedTestType of deletedTestTypes) {
                  await testTypeDelete({ variables: { id: deletedTestType.id } })
               }
               setIsChanged(false);
            }
         },
      }), [testTypes, deletedTestTypes, isChanged, testTypeCreate, testTypeUpdate, testTypeDelete]);

      useEffect(() => {
         if (data?.testTypes) {
            setTestTypes(data?.testTypes);
         }
      }, [data]);

      /**
       * Adds a test length to the list.
       */
      const handleAddTestType = () => {
         const testType = { id: uniqueId('new'), operatorId };
         setTestType(testType);
         setShowEdit(true);
         setIsChanged(true);
         onChange && onChange();
      };

      /**
       * Edit a test length.
       */
      const handleEditTestType = () => {
         setShowEdit(true);
      };

      /**
       * Delete the test length.
       */
      const handleDeleteTestType = () => {
         setIsChanged(true);
         if (!isString(testTypeValue.id)) {
            const cacheDeleted = clone(deletedTestTypes);
            cacheDeleted.push(testTypeValue);
            setDeletedTestTypes(cacheDeleted);
         }

         const index = findIndex(testTypes, { id: testTypeValue.id });

         if (index >= 0) {
            const cache = [...testTypes];
            removeOne(cache, index);
            setTestTypes(cache);
            onChange && onChange();
         } else {
            console.log('Could not find test length to delete', testTypeValue);
         }
      }

      /**
       * Handle selecting an test length from the table.
       * @param testType The testType selected
       * @param isDoubleClick Indicates if the selection was a double click.
       */
      const handleSelect = (testType, isDoubleClick = false) => {
         setTestType(testType);
         setTestTypeId(testType?.id);
         setShowEdit(isDoubleClick);
      };

      /**
       * Get the columns for the test length table.
       */
      const columns = useMemo(
         () => [
            {
               Header: 'Test Type',
               accessor: 'testType',
            }
         ], []
      );

      /**
       * When the user submits changes to the selected testType, update the list and hide the dialog.
       * @type {(function(*): void)|*}
       */
      const handleSubmit = useCallback((testType) => {
         const cache = [...testTypes]; // Create a shallow copy of the array
         const index = findIndex(cache, { id: testType.id });

         if (index < 0) {
            // If the item doesn't exist, add it
            setTestTypes([...cache, testType]);
         } else {
            // If the item exists, update it
            const updatedCache = [...cache]; // Create a new copy for updates
            updatedCache[index] = testType; // Update the specific index
            setTestTypes(updatedCache); // Set the new array to state
         }

         setTestTypeId(testTypeValue?.id);
         setTestType(testType);
         setShowEdit(false);
         setIsChanged(true);
         onChange && onChange();
      }, [testTypeValue, testTypes]);

      /**
       * On close, close the edit dialog.
       */
      const handleClose = () => {
         setShowEdit(false);
      };

      return (
         <>
            <DataLoadingAndErrors error={error} isLoading={loading} />
            <ItemTable titleKey={'operator.testType.label'} columns={columns} items={testTypes}
               onSelect={handleSelect}
               selectId={testTypeId}
               showSearchTextField={false}>
               <Button color='primary' onClick={handleAddTestType} style={{ marginLeft: 8 }}>
                  <FHGTypography noWrap id={'operator.addTestType.button'} />
               </Button>
               <Button color='primary' onClick={handleEditTestType} style={{ marginLeft: 8 }} disabled={!testTypeId}>
                  <FHGTypography noWrap id={'operator.editTestType.button'} disabled={!testTypeId} />
               </Button>
               <Button color='primary' onClick={handleDeleteTestType} style={{ marginLeft: 8 }} disabled={!testTypeId}>
                  <FHGTypography noWrap id={'operator.deleteTestType.button'} disabled={!testTypeId} />
               </Button>
            </ItemTable>
            {showEdit && (
               <TestTypeEdit testTypeValue={testTypeValue} onSubmit={handleSubmit} onClose={handleClose} />
            )}
         </>
      );
   });

export default TestTypeTable;
