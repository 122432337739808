import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import gql from 'graphql-tag';
import { clone } from 'lodash';
import { isString } from 'lodash';
import { findIndex } from 'lodash';
import uniqueId from 'lodash/uniqueId';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import React from 'react';
import ItemTable from '../../components/ItemTable';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import FHGTypography from '../../fhg/components/Typography';
import { removeOne } from '../../fhg/utils/Utils';
import TestSystemPressureEdit from './TestSystemPressureEdit';

const TEST_SYSTEM_PRESSURE_INFO_FRAGMENT = gql`
  fragment testSystemPressureInfo on TestSystemPressure {
    id
    pressureText
    operatorId
  }
`;

const TEST_SYSTEM_PRESSURE_ALL_WHERE_QUERY = gql`
   query getTestSystemPressureAllWhere($operatorId: [Int] ) {
      testSystemPressures:testSystemPressure_AllWhere(testSystemPressureSearch: {operatorId: $operatorId}) {
         ...testSystemPressureInfo
      }
   }
   ${TEST_SYSTEM_PRESSURE_INFO_FRAGMENT}
`;

const TEST_SYSTEM_PRESSURE_CREATE = gql`
   mutation testSystemPressureCreate($pressureText: String!, $operatorId: Int!)
   {
      testSystemPressure: testSystemPressure_Create(testSystemPressure: {pressureText: $pressureText, operatorId: $operatorId}) {
         ...testSystemPressureInfo
      }
   }
   ${TEST_SYSTEM_PRESSURE_INFO_FRAGMENT}
`;

const TEST_SYSTEM_PRESSURE_UPDATE = gql`
   mutation testSystemPressureUpdate($id: Int!, $operatorId: Int!, $pressureText: String)
   {
      testSystemPressure: testSystemPressure_Update(testSystemPressureId: $id, testSystemPressure: {operatorId: $operatorId, pressureText: $pressureText}) {
         ...testSystemPressureInfo
      }
   }
   ${TEST_SYSTEM_PRESSURE_INFO_FRAGMENT}
`;

const TEST_SYSTEM_PRESSURE_DELETE = gql`
   mutation testSystemPressureDelete($id: Int!) {
      testSystemPressure_Delete(testSystemPressureId: $id)
   }
`;

const TestSystemPressureTable = forwardRef(
   function TestSystemPressureTable({ operatorId, onChange }, ref) {
      const [testSystemPressureId, setTestSystemPressureId] = useState();
      const [testSystemPressureValue, setTestSystemPressure] = useState();
      const [deletedTestSystemPressures, setDeletedTestSystemPressures] = useState([]);

      const { data, loading, error } = useQuery(TEST_SYSTEM_PRESSURE_ALL_WHERE_QUERY, { variables: { operatorId } });

      const [testSystemPressureCreate] = useMutationFHG(TEST_SYSTEM_PRESSURE_CREATE, {
         refetchQueries: () => ([{ query: TEST_SYSTEM_PRESSURE_ALL_WHERE_QUERY, variables: { operatorId } }])
      });
      const [testSystemPressureUpdate] = useMutationFHG(TEST_SYSTEM_PRESSURE_UPDATE, {
         refetchQueries: () => ([{ query: TEST_SYSTEM_PRESSURE_ALL_WHERE_QUERY, variables: { operatorId } }])
      });

      const [testSystemPressureDelete] = useMutationFHG(TEST_SYSTEM_PRESSURE_DELETE, {
         refetchQueries: () => ([{ query: TEST_SYSTEM_PRESSURE_ALL_WHERE_QUERY, variables: { operatorId } }])
      });

      const [testSystemPressures, setTestSystemPressures] = useState();

      const [showEdit, setShowEdit] = useState(false);
      const [isChanged, setIsChanged] = useState(false);

      useImperativeHandle(ref, () => ({
         async submit() {
            if (isChanged) {
               for (const testSystemPressure of testSystemPressures) {
                  let mutate = isString(testSystemPressure.id) ? testSystemPressureCreate : testSystemPressureUpdate;
                  await mutate({ variables: { ...testSystemPressure } });
               }
               for (const deletedTestSystemPressure of deletedTestSystemPressures) {
                  await testSystemPressureDelete({ variables: { id: deletedTestSystemPressure.id } })
               }
               setIsChanged(false);
            }
         },
      }), [testSystemPressures, deletedTestSystemPressures, isChanged, testSystemPressureCreate, testSystemPressureUpdate, testSystemPressureDelete]);

      useEffect(() => {
         if (data?.testSystemPressures) {
            setTestSystemPressures(data?.testSystemPressures);
         }
      }, [data]);

      /**
       * Adds a test length to the list.
       */
      const handleAddTestSystemPressure = () => {
         const testSystemPressure = { id: uniqueId('new'), operatorId };
         setTestSystemPressure(testSystemPressure);
         setShowEdit(true);
         setIsChanged(true);
         onChange && onChange();
      };

      /**
       * Edit a test length.
       */
      const handleEditTestSystemPressure = () => {
         setShowEdit(true);
      };

      /**
       * Delete the test length.
       */
      const handleDeleteTestSystemPressure = () => {
         setIsChanged(true);
         if (!isString(testSystemPressureValue.id)) {
            const cacheDeleted = clone(deletedTestSystemPressures);
            cacheDeleted.push(testSystemPressureValue);
            setDeletedTestSystemPressures(cacheDeleted);
         }

         const index = findIndex(testSystemPressures, { id: testSystemPressureValue.id });

         if (index >= 0) {
            const cache = [...testSystemPressures];
            removeOne(cache, index);
            setTestSystemPressures(cache);
            onChange && onChange();
         } else {
            console.log('Could not find test length to delete', testSystemPressureValue);
         }
      }

      /**
       * Handle selecting an test length from the table.
       * @param testSystemPressure The testSystemPressure selected
       * @param isDoubleClick Indicates if the selection was a double click.
       */
      const handleSelect = (testSystemPressure, isDoubleClick = false) => {
         setTestSystemPressure(testSystemPressure);
         setTestSystemPressureId(testSystemPressure?.id);
         setShowEdit(isDoubleClick);
      };

      /**
       * Get the columns for the test length table.
       */
      const columns = useMemo(
         () => [
            {
               Header: 'Pressure Text',
               accessor: 'pressureText',
            }
         ], []
      );

      /**
       * When the user submits changes to the selected testSystemPressure, update the list and hide the dialog.
       * @type {(function(*): void)|*}
       */
      const handleSubmit = useCallback((testSystemPressure) => {
         const cache = [...testSystemPressures]; // Create a shallow copy of the array
         const index = findIndex(cache, { id: testSystemPressure.id });

         if (index < 0) {
            // If the item doesn't exist, add it
            setTestSystemPressures([...cache, testSystemPressure]);
         } else {
            // If the item exists, update it
            const updatedCache = [...cache]; // Create a new copy for updates
            updatedCache[index] = testSystemPressure; // Update the specific index
            setTestSystemPressures(updatedCache); // Set the new array to state
         }
         setTestSystemPressureId(testSystemPressureValue?.id);
         setTestSystemPressure(testSystemPressure);
         setShowEdit(false);
         setIsChanged(true);
         onChange && onChange();
      }, [testSystemPressureValue, testSystemPressures]);

      /**
       * On close, close the edit dialog.
       */
      const handleClose = () => {
         setShowEdit(false);
      };

      return (
         <>
            <DataLoadingAndErrors error={error} isLoading={loading} />
            <ItemTable titleKey={'operator.systemPressures.label'} columns={columns} items={testSystemPressures}
               onSelect={handleSelect}
               selectId={testSystemPressureId}
               showSearchTextField={false}>
               <Button color='primary' onClick={handleAddTestSystemPressure} style={{ marginLeft: 8 }}>
                  <FHGTypography noWrap id={'operator.addTestType.button'} />
               </Button>
               <Button color='primary' onClick={handleEditTestSystemPressure} style={{ marginLeft: 8 }} disabled={!testSystemPressureId}>
                  <FHGTypography noWrap id={'operator.editTestType.button'} disabled={!testSystemPressureId} />
               </Button>
               <Button color='primary' onClick={handleDeleteTestSystemPressure} style={{ marginLeft: 8 }} disabled={!testSystemPressureId}>
                  <FHGTypography noWrap id={'operator.deleteTestType.button'} disabled={!testSystemPressureId} />
               </Button>
            </ItemTable>
            {showEdit && (
               <TestSystemPressureEdit systemPressureValue={testSystemPressureValue} onSubmit={handleSubmit} onClose={handleClose} />
            )}
         </>
      );
   });

export default TestSystemPressureTable;
