import PropTypes from 'prop-types';
import React from 'react';
import {Redirect, Route} from 'react-router-dom';

/**
 * A secure route that will redirect to the default route.
 *
 * Reviewed: 3/26/20
 *
 * @param hasPrivilege True if the user has privilege to the route.
 * @param children The children of the component.
 * @param rest The other props for the Route.
 * @return {*}
 * @constructor
 */
// export default function PrivilegeRoute({hasPrivilege, children, ...rest}) {
//    if (hasPrivilege) {
//       return (
//          <Route {...rest}>
//             {children}
//          </Route>
//       );
//    }
//    return <Redirect to='/'/>
// }

export default function PrivilegeRoute({ hasPrivilege = false, children, ...rest }) {
   if (hasPrivilege) {
      return (
         <Route {...rest}>
            {children}
         </Route>
      );
   }
   return <Redirect to='/' />;
}

PrivilegeRoute.propTypes = {
   hasPrivilege: PropTypes.bool.isRequired,  //Function that returns true if the user can access the route.
};

// PrivilegeRoute.defaultProps = {
//    hasPrivilege: false,
// };