import get from 'lodash/get';
import PropTypes from 'prop-types';
import {parse} from 'query-string';
import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import Grid from '../fhg/components/Grid';
import TableFHG from '../fhg/components/table/TableFHG';

/**
 * Table with search from the URL. Adds searching using the URL search parameters.
 *
 * Reviewed: 3/26/20
 */
export default function ItemTable({
   titleKey = '',               // Default value inside the parameter
   columns = [],                // Default value inside the parameter
   items = [],                  // Default value inside the parameter
   onSelect = () => {},         // Default value inside the parameter
   selectId = null,             // Default value inside the parameter
   skipPageReset = false,       // Default value inside the parameter
   children = null,             // Default value inside the parameter
   showSearchTextField = true   // Default value inside the parameter
 }) {
   const location = useLocation();
 
   const [searchFilter, setSearchFilter] = useState();
 
   useEffect(() => {
     setSearchFilter(get(parse(location.search), 'searchText'));
   }, [location.search]);
 
   return (
     <Grid name={'Item Table Root Grid'} container item direction={'column'} fullWidth={false} resizable>
       <TableFHG
         titleKey={titleKey}
         columns={columns}
         data={items}
         onSelect={onSelect}
         selectId={selectId}
         searchFilter={searchFilter}
         skipPageReset={skipPageReset}
         showSearchTextField={showSearchTextField}
       >
         {children}
       </TableFHG>
     </Grid>
   );
 }

ItemTable.propTypes = {
   titleKey: PropTypes.string,   // The message key of the title.
   columns: PropTypes.array,     // The columns for the table.
   items: PropTypes.array,       // Items for the table.
   onSelect: PropTypes.func,     // Callback when an item row is selected.
   selectId: PropTypes.any,     // Select ID of the row to be selected.
};

// ItemTable.defaultProps = {
//    items: [],
// };

