import {useQuery} from '@apollo/client';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import {toNumber} from 'lodash';
import get from 'lodash/get';
import moment from 'moment';
import React, {useState, useEffect, useContext, useMemo} from 'react';
import {useParams, useHistory, useLocation} from 'react-router-dom';
import AppBarContext from '../../components/AppBarContext';
import AuthContext from '../../components/AuthContext';
import ItemTable from '../../components/ItemTable';
import {DATE_FORMAT, USERS_PATH, USER_PATH, OPERATORS_PATH} from '../../Constants';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import Grid from '../../fhg/components/Grid';
import FHGTypography from '../../fhg/components/Typography';
import User, {USERS_QUERY} from './User';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { bool } from 'prop-types';

const useUsersStyles = makeStyles(theme => ({
   root: {
      [theme.breakpoints.down('xs')]: {
         flexDirection: 'column'
      },
      [theme.breakpoints.up('sm')]: {
         flexDirection: 'row'
      },
   },
   paperStyle: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      maxHeight: `calc(100% - ${theme.spacing(4)}px)`,
      [theme.breakpoints.down('sm')]: {
         margin: theme.spacing(1),
         maxHeight: `calc(100% - ${theme.spacing(2)}px)`,
      },
      // maxWidth: 800,
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
   },
   privacyPolicyPaperStyle: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
      overflow: 'auto',
   },
   privacyPolicyStyle: {
      position: 'relative',
      height: 160,
   },
}), {name: 'usersStyles'});

/**
 * The component to select users and display the user information.
 *
 * Reviewed: 3/26/20
 */
export default function Users() {
   const {operatorId: operatorIdAsString, userId: userIdAsString} = useParams();
   const operatorId = toNumber(operatorIdAsString);
   const userId = toNumber(userIdAsString);
   const classes = useUsersStyles();
   const history = useHistory();
   const location = useLocation();
   const {data, loading, error} = useQuery(USERS_QUERY, {variables: {operatorId}, skip: !operatorId});
   const [users, setUsers] = useState([]);
   const [user, setUser] = useState();
   const {isCreate, isMultiple} = get(location, 'state', {isCreate: false, isMultiple: false});
   const {setSubtitleValues} = useContext(AppBarContext);
   const {isSuperAdmin} = useContext(AuthContext);
   const [showActive, setShowActive] = useState(true);
   const [filteredUsers, setFilteredUsers] = useState([]);

   /**
    * If no operatorID and this is a super admin, redirect back to the operators list.
    */
   useEffect(() => {
      if (!operatorId && isSuperAdmin) {
         history.replace(OPERATORS_PATH, {state: {isCreate: false, isMultiple: false}});
      }
      // eslint-disable-next-line
   }, [operatorId]);

   /**
    * If isCreate or isMultiple are set on initialization, force both to false. This prevents getting to those using
    * browser history.
    */
   useEffect(() => {
      if (isCreate || isMultiple) {
         history.replace(location.pathname, {state: {isCreate: false, isMultiple: false}});
      }
      // eslint-disable-next-line
   }, [operatorIdAsString]);

   /**
    * Set the user list from the query results.
    */
   useEffect(() => {
      if (!!data) {
         setUsers(get(data, 'users') || []);
         setSubtitleValues({operator: get(data, 'operator.name') || ''});
      }
   }, [data, setSubtitleValues]);

   useEffect(() => {
      setFilteredUsers(users.filter(user => user.isActive === showActive));
    }, [users, showActive]);

   /**
    * Sorts boolean column values.
    */
   const booleanSort = useMemo(() => {
      return (rowA, rowB, columnId) => {
         if (rowA.original[columnId] === rowB.original[columnId]) {
            return 0;
         }
         return rowA.original[columnId] === true ? 1 : -1;
      }
   }, []);

   /**
    * Get the columns for the users table.
    */
   const columns = useMemo(
      () => [
         {
            Header: <FHGTypography id={'operators.name.column'}/>,
            accessor: 'name',
         }, {
            Header: <FHGTypography id={'operators.email.column'}/>,
            accessor: 'email',
         }, {
            Header: <FHGTypography id={'operators.lastTest.column'}/>,
            accessor: 'lastTestDateTime',
            Cell: ({row}) => moment(row.values.lastTestDateTime, 'x').format(DATE_FORMAT),
         }, {
            Header: <FHGTypography id={'operators.lastLogin.column'}/>,
            accessor: 'lastLoginDateTime',
            Cell: ({row}) => moment(row.values.lastLoginDateTime, 'x').format(DATE_FORMAT),
         }, {
            Header: <FHGTypography id={'operators.active.column'}/>,
            accessor: 'isActive',
            disableSortBy: false,
            sortType: booleanSort,
            Cell: ({row}) => {
               return row.values.isActive ? 'True' : 'False'
            }
         },
      ], [booleanSort]
   );

   /**
    * Handle selecting a user from the table.
    * @param user the user selected.
    */
   const handleSelect = (user) => {
      setUser(user);
      const path = USER_PATH.replace(':operatorId', operatorIdAsString).replace(':userId?', user.id);
      history.replace(path);
   };

   /**
    * Handle adding a user. Set the URL isCreate to true.
    */
   const handleAddUser = () => {
      setUser();
      history.replace(USERS_PATH.replace(':operatorId', operatorIdAsString), {isCreate: true, isMultiple: false});
   };

   /**
    * Handle adding multiple users. Set the URL isCreate to true and isMultiple to true.
    */
   const handleAddMultipleUser = () => {
      setUser();
      history.replace(USERS_PATH.replace(':operatorId', operatorIdAsString), {isCreate: true, isMultiple: true});
   };

   // Show the spinner while loading.
   if (!data) {
      if (loading) {
         return (
            <DataLoadingAndErrors isLoading={loading}/>
         );
      }
      return null;
   }

   const toggleActiveChecked = (event) => {
      setShowActive(!showActive)
   };

   const privacyPolicy = get(data, 'operator.privacyPolicy');
   
   return (
      <Grid name={'Users Root Grid'} container direction={'column'} wrap={'nowrap'} style={{position: 'relative'}}>
         <DataLoadingAndErrors error={error} isLoading={loading}/>
         {privacyPolicy && (
            <Grid container direction={'column'} className={classes.privacyPolicyStyle} overflow={'auto'} resizable={false}>
               <Paper className={classes.privacyPolicyPaperStyle}>
                  <FHGTypography>
                     <p style={{textAlign: 'center'}}>
                        <b>THIS SYSTEM IS FOR AUTHORIZED USERS ONLY</b>
                     </p>
                  </FHGTypography>
                  <FHGTypography>{privacyPolicy}</FHGTypography>
               </Paper>
            </Grid>
         )}
         <Grid name={'Users Content Grid'} container className={classes.root} fullWidth>
            <Grid name={'Users Table Grid'} item resizable fullHeight fullWidth
                  style={{ width: '100%', maxHeight: 'fit-content'}}
                  xs={12} sm={6} lg={6}>
               <Paper square className={classes.paperStyle} fullWidth>
                  <Grid container resizable={false} fullHeight fullWidth direction={'column'} wrap={'nowrap'}>
                     <div style={{ maxHeight: '400px', overflowY: 'auto', width: '100%' }}>
                     <Grid item resizable={false} style={{
    minWidth: 'fit-content',
    overflow: 'visible',
    display: 'flex',
    justifyContent: 'flex-end',
  }} overflow={'visible'}>
                        <FormControlLabel
                           control={<Switch checked={showActive} onChange={toggleActiveChecked} color={'primary'}/>}
                           label={<FHGTypography noWrap id={'user.active.users'}/>}
                           style={{marginRight: 0}}
                        />
                     </Grid>
                     <ItemTable titleKey={'users.title'} columns={columns} items={filteredUsers} onSelect={handleSelect}
                                selectId={userId} style={{ width: '1000px'}}/>
                     </div>
                    
                     <Grid container direction={'row'} style={{paddingTop: 8}} resizable={false}>
                        <Button color='primary' onClick={handleAddUser}>
                           <FHGTypography noWrap id={'users.addUser.button'}/>
                        </Button>
                        <Button color='primary' onClick={handleAddMultipleUser}>
                           <FHGTypography noWrap id={'users.addMultiple.button'}/>
                        </Button>
                     </Grid>
                  </Grid>
               </Paper>
            </Grid>
            <Grid name={'Single User Grid'} item resizable fullHeight fullWidth xs={12} sm={6} lg={6}>
               <User key={`${isCreate} ${isMultiple} ${userId}`} initialUser={user}/>
            </Grid>
         </Grid>
      </Grid>
   );
}
