import Button from '@mui/material/Button';
import {createTheme, ThemeProvider, responsiveFontSizes} from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import React from 'react';
import DocumentTitle from 'react-document-title';
import {LOGO_LARGE_WHITE} from '../Constants';
import Grid from '../fhg/components/Grid';
import FHGTypography from '../fhg/components/Typography';

const useStyles = makeStyles(theme => ({
   root: {
      backgroundImage: 'linear-gradient(#1E528F, #0F2948)',
      height: '100vh',
      overflow: 'auto',
   },
   titleStyle: {
      color: 'white',
      width: '100%',
      textAlign: 'center',
   },
   contentStyle: {
      maxHeight: 350,
      height: '80%',
      top: '20%',
      position: 'relative',
      minHeight: 200,
      padding: theme.spacing(1),
   },
   buttonStyle: {
      color: theme.palette.accent, //'#CD3B49',
      border: `2px solid ${theme.palette.accent}`,
      backgroundColor: 'white',
      borderRadius: 0,
      textTransform: 'none',
      minWidth: 200,
      '&:hover': {
         backgroundColor: theme.palette.background.default, //'#EEEEEE',
      }
   },
   imageStyle: {
      // height: 90,
      [theme.breakpoints.down('sm')]: {
         height: 83,
      },
      [theme.breakpoints.down('xs')]: {
         height: 76,
         maxWidth: '100%',
      },
   },
   formStyle: {
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
   },
   titleAndActionStyle: {
      height: '100%',
      maxHeight: 140,
      marginTop: 40,
      marginBottom: -2,
   },
}), {name: 'securityCardStyles'});

// Create a theme for this card that has responsive font sizes.
let theme = createTheme();
theme = responsiveFontSizes(theme);

/**
 * The card that handles login and other startup cards to look like the login.
 *
 * Reviewed: 3/26/20
 */
export default function SecurityCard({title, titleId, actionLabelKey, onAction, isForm, disabled, children}) {
   const classes = useStyles();
   const FormComponent = isForm ? 'form' : 'div';

   return (
      <ThemeProvider theme={theme}>
         <DocumentTitle title={title}>
            <Grid container justifyContent={'space-between'} alignItems={'center'} direction={'column'}
                  className={classes.root}>

               <Grid name='Security Content' item container justifyContent={'space-between'} className={classes.contentStyle}
                     direction={'column'} alignItems={'center'}>
                  <Grid item resizable={false}>
                     <img alt='' className={classes.imageStyle} src={LOGO_LARGE_WHITE}/>
                  </Grid>
                  <Grid name='Security Title and Actions' container item resizable={true} justifyContent={'space-between'}
                        alignItems={'center'} direction={'column'}
                        wrap={'nowrap'} className={classes.titleAndActionStyle}>
                     <FormComponent onSubmit={onAction} className={classes.formStyle}>
                        <Grid name='Title or Children' item resizable={false} fullWidth>
                           {(titleId || title) && <>
                              <FHGTypography className={classes.titleStyle} variant='h3' noWrap
                                          id={titleId}>{title}</FHGTypography>
                           </>}
                           {children && <>
                              {children}
                           </>}
                        </Grid>
                        <Grid item resizable={false} style={{alignSelf: 'center'}}>
                           <Button aria-label='Home' color='primary' className={classes.buttonStyle}
                                   onClick={onAction} disabled={disabled}
                                   type='submit'>
                              <FHGTypography variant='h6' color='inherit' noWrap id={actionLabelKey}/>
                           </Button>
                        </Grid>
                     </FormComponent>
                  </Grid>
               </Grid>
            </Grid>
         </DocumentTitle>
      </ThemeProvider>
   );
}

SecurityCard.propTypes = {
   title: PropTypes.string,                  //The title to display for the card.
   actionLabelKey: PropTypes.string.isRequired, // The label for the action button
   onAction: PropTypes.func.isRequired,      // The callback when the action is performed.
   isForm: PropTypes.bool,                   // Indicates if a form should enclose the children.
   children: PropTypes.any,                  //Children for the component.
};

// SecurityCard.defaultProps = {
//    isForm: false,
// };