// module.exports = {
//    appId: '21b6c308-15bd-4081-afb7-4a47e1922ca2',
//    redirectUri: 'http://localhost:3000',
//    scopes: [
//       'user.read',
//       'calendars.read'
//    ]
// };
module.exports = {
   // tenantId: '9188040d-6c67-4c5b-b112-36a304b66dad',
   tenantId: 'de202d78-6b95-4512-9e0d-bc7c7ffb4d63',
   appId: '17870823-fbb2-48ef-b3ff-73d7e3d53dd7',
   redirectUri: window.location.origin,
   scopes: ['user.Read'],
   authorities : [
      {
         "type": "AAD",
         "audience": {
            "type": "AzureADandPersonalMicrosoftAccount",
            "tenant_id": "common"
         }
      }
      ]
};