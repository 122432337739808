import { useCallback } from 'react';
import React, { useState } from 'react';
import FHGTextField from '../../components/TextField';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';

import { Select, MenuItem, InputLabel } from '@mui/material';
import { forEach } from 'lodash';
// import { Select, MenuItem } from '@fhg/components';

/**
 * Component to edit a single test length.
 *
 * @param testLengthValue The test length to edit.
 * @param onSubmit Submit the changes to the test length.
 * @param onClose Close the edit dialog.
 * @return {JSX.Element}
 * @constructor
 */
export default function TestLengthEdit({ testLengthValue, testTypes, onSubmit, onClose }) {

   const [editValues, handleChange, { isChanged, getValue, defaultValues }] = useEditData(testLengthValue);

   /**
    * Submit the changes to the test length.
    * @type {(function(*): void)|*}
    */
   const handleSubmit = useCallback((event) => {

      console.log("handleSubmit " + JSON.stringify(editValues));

      if (event) {
         event.preventDefault();
         event.stopPropagation();
      }

      if (isChanged) {
         onSubmit && onSubmit({ ...defaultValues, ...editValues });
      }
   }, [editValues, defaultValues, isChanged, onSubmit]);

   const existingTestType = getValue('testType');

   const options = [];
   var defaultValue = '';

   if (existingTestType != null) {
      options.push({ value: existingTestType.id, label: existingTestType.testType });
      defaultValue = existingTestType.id;
   } else {
      for (const element of testTypes) {
         options.push({ value: element.id, label: element.testType })
      }
      if (testTypes.length == 1) {
         defaultValue = testTypes[0].id;
         editValues["testType"] = testTypes[0];
         editValues["testTypeId"] = testTypes[0].id;
      }
   }

   const [selectedValue, setSelectedValue] = useState(defaultValue);


   const handleChangeSelect = (event) => {
      setSelectedValue(event.target.value);
      const item = testTypes.find(item => item.id === event.target.value);
      editValues["testType"] = item;
      editValues["testTypeId"] = item.id;
   };

   return (
      <ModalDialog open={true} titleKey={'operator.testLengthEdit.title'} onSubmit={(event) => handleSubmit(event)} maxWidth={'sm'}
         fullWidth onClose={onClose}>
         <FHGTextField name='minimumPipeDiameter' type='decimal' autoFocus label={'Max. Pipe Diameter'}
            style={{ marginBottom: 8 }}
            onChange={handleChange} 
            value={getValue('minimumPipeDiameter')} 
            required />
         <FHGTextField name='minimumPipeLength' type='number' label={'Min. Footage'} style={{ marginBottom: 8 }}
            onChange={handleChange} value={getValue('minimumPipeLength')} required />
         <FHGTextField name='testLength' type='number' label={'Test Length'} onChange={handleChange}
            value={getValue('testLength')} required />

         <InputLabel id="select-label" style={ {color: 'black', fontWeight: '500', marginTop: '20px', marginBottom: '10px', fontSize: '16px' } }>Test Type *</InputLabel>
         <Select
            labelId="select-label"
            name='testTypeId'
            value={selectedValue}
            required
            onChange={handleChangeSelect}>
            <MenuItem value="" disabled>Select an option</MenuItem>
            {options.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                  {option.label}
               </MenuItem>
            ))}
         </Select>

      </ModalDialog>
   );
}
