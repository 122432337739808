import { useCallback } from 'react';
import React, { useState } from 'react';
import FHGTextField from '../../components/TextField';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';

/**
 * Component to edit a single system pressure.
 *
 * @param systemPressureValue The system pressure to edit.
 * @param onSubmit Submit the changes to the system pressure.
 * @param onClose Close the edit dialog.
 * @return {JSX.Element}
 * @constructor
 */
export default function TestSystemPressureEdit({ systemPressureValue: systemPressureValue, onSubmit, onClose }) {

   const [editValues, handleChange, { isChanged, getValue, defaultValues }] = useEditData(systemPressureValue);

   /**
    * Submit the changes to the system pressure.
    * @type {(function(*): void)|*}
    */
   const handleSubmit = useCallback((event) => {

      console.log("handleSubmit " + JSON.stringify(editValues));

      if (event) {
         event.preventDefault();
         event.stopPropagation();
      }

      if (isChanged) {
         onSubmit && onSubmit({ ...defaultValues, ...editValues });
      }
   }, [editValues, defaultValues, isChanged, onSubmit]);

   return (
      <ModalDialog open={true} titleKey={'operator.systemPressureEdit.title'} onSubmit={(event) => handleSubmit(event)} maxWidth={'sm'}
         fullWidth onClose={onClose}>
         <FHGTextField name='pressureText' type='text' autoFocus label={'System Pressure'}
            style={{ marginBottom: 8 }}
            onChange={handleChange} value={getValue('pressureText')} required />
      </ModalDialog>
   );
}
