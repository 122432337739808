import { useQuery } from '@apollo/client';
import Button from '@mui/material/Button';
import gql from 'graphql-tag';
import { clone, update } from 'lodash';
import { isString } from 'lodash';
import { findIndex } from 'lodash';
import uniqueId from 'lodash/uniqueId';
import { forwardRef } from 'react';
import { useImperativeHandle } from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useMemo } from 'react';
import React from 'react';
import ItemTable from '../../components/ItemTable';
import useMutationFHG from '../../fhg/components/data/useMutationFHG';
import DataLoadingAndErrors from '../../fhg/components/DataLoadingAndErrors';
import FHGTypography from '../../fhg/components/Typography';
import { removeOne } from '../../fhg/utils/Utils';
import PressureSpecEdit from './PressureSpecEdit';

const TEST_TYPE_INFO = gql`
  fragment testTypeInfo on TestType {
    id
    testType
    operatorId
  }
`;

const TEST_SYSTEM_PRESSURE_INFO = gql`
  fragment testSystemPressureInfo on TestSystemPressure {
    id
    pressureText
    operatorId
  }
`;

const PRESSURE_SPEC_FRAGMENT = gql`
   ${TEST_TYPE_INFO}
   ${TEST_SYSTEM_PRESSURE_INFO}
   fragment pressureSpecInfo on PressureSpecs {
      id
      operatorId
      testType {
         ...testTypeInfo
      }
      testSystemPressure {
         ...testSystemPressureInfo
      }
      minimumAllowablePressure
      maximumAllowablePressure
      maximumOnTestPressure
      maximumAllowablePressureDrop
   }
`;

const TEST_PRESSURE_SPEC_ALL_WHERE_QUERY = gql`
   query getPressureSpecsAllWhere($operatorId: [Int] ) {
      pressureSpecs:pressureSpecs_AllWhere(pressureSpecsSearch: {operatorId: $operatorId}) {
         ...pressureSpecInfo
      }
   }
   ${PRESSURE_SPEC_FRAGMENT}
`;

const TEST_TYPES_BY_OPERATOR_QUERY = gql`
   query getTestTypesByOperator($operatorId: [Int] ) {
      testTypes:testType_AllWhere(testTypeSearch: {operatorId: $operatorId}) {
         ...testTypeInfo
      }
   }
   ${TEST_TYPE_INFO}
`;

const TEST_SYSTEM_PRESSURES_BY_OPERATOR_QUERY = gql`
   query getTestSystemPressuresByOperator($operatorId: [Int] ) {
      testSystemPressures:testSystemPressure_AllWhere(testSystemPressureSearch: {operatorId: $operatorId}) {
         ...testSystemPressureInfo
      }
   }
   ${TEST_SYSTEM_PRESSURE_INFO}
`;

const TEST_PRESSURE_SPEC_CREATE = gql`
   mutation pressureSpecsCreate($minimumAllowablePressure: Float!, $maximumAllowablePressure: Float!, $operatorId: Int!, $maximumOnTestPressure: Float!, $maximumAllowablePressureDrop: Float! $testTypeId: Int!, $testSystemPressureId: Int!)
   {
      pressureSpecs: pressureSpecs_Create(pressureSpecs: {minimumAllowablePressure: $minimumAllowablePressure, maximumAllowablePressure: $maximumAllowablePressure, operatorId: $operatorId, maximumOnTestPressure: $maximumOnTestPressure, maximumAllowablePressureDrop: $maximumAllowablePressureDrop, testTypeId: $testTypeId, testSystemPressureId: $testSystemPressureId}) {
         ...pressureSpecInfo
      }
   }
   ${PRESSURE_SPEC_FRAGMENT}
`;

const TEST_PRESSURE_SPEC_UPDATE = gql`
   mutation pressureSpecsUpdate($id: Int!, $minimumAllowablePressure: Float!, $maximumAllowablePressure: Float!, $operatorId: Int!, $maximumOnTestPressure: Float!, $maximumAllowablePressureDrop: Float!, $testTypeId: Int!, $testSystemPressureId: Int!)
   {
      pressureSpecs: pressureSpecs_Update(pressureSpecsId: $id, pressureSpecs: {minimumAllowablePressure: $minimumAllowablePressure, maximumAllowablePressure: $maximumAllowablePressure, operatorId: $operatorId, maximumOnTestPressure: $maximumOnTestPressure, maximumAllowablePressureDrop: $maximumAllowablePressureDrop, testTypeId: $testTypeId, testSystemPressureId: $testSystemPressureId}) {
         ...pressureSpecInfo
      }
   }
   ${PRESSURE_SPEC_FRAGMENT}
`;

const TEST_PRESSURE_SPEC_DELETE = gql`
   mutation pressureSpecsDelete($id: Int!) {
      pressureSpecs_Delete(pressureSpecsId: $id)
   }
`;

const PressureSpecTable = forwardRef(
   function PressureSpecTable({ operatorId, onChange }, ref) {
      const [pressureSpecId, setPressureSpecId] = useState();
      const [pressureSpecValue, setPressureSpec] = useState();
      const [deletedPressureSpect, setDeletedPressureSpecs] = useState([]);

      const { data, loading, error } = useQuery(TEST_PRESSURE_SPEC_ALL_WHERE_QUERY, { variables: { operatorId }, skip: !operatorId });

      const {data: dataTestTypes, loadingTestTypes, errorTestTypes} = useQuery(TEST_TYPES_BY_OPERATOR_QUERY, { variables: {operatorId} });

      const {data: dataTestSystemPressures, loading2, error2} = useQuery(TEST_SYSTEM_PRESSURES_BY_OPERATOR_QUERY, { variables: {operatorId} });
      
      const [pressureSpecCreate] = useMutationFHG(TEST_PRESSURE_SPEC_CREATE, {
         refetchQueries: () => ([{ query: TEST_PRESSURE_SPEC_ALL_WHERE_QUERY, variables: { operatorId } }])
      });
      const [pressureSpecUpdate] = useMutationFHG(TEST_PRESSURE_SPEC_UPDATE, {
         refetchQueries: () => ([{ query: TEST_PRESSURE_SPEC_ALL_WHERE_QUERY, variables: { operatorId } }])
      });


      const [pressureSpecDelete] = useMutationFHG(TEST_PRESSURE_SPEC_DELETE, {
         refetchQueries: () => ([{ query: TEST_PRESSURE_SPEC_ALL_WHERE_QUERY, variables: { operatorId } }])
      });

      const [pressureSpecs, setPressureSpecs] = useState();
      const [testTypes, setTestTypes] = useState();
      const [testSystemPressures, setTestSystemPressures] = useState();

      const [showEdit, setShowEdit] = useState(false);
      const [isChanged, setIsChanged] = useState(false);


      useImperativeHandle(ref, () => ({
         async submit() {
             if (isChanged) {
                 
                 for (var ps of pressureSpecs) {
                     let mutate = isString(ps.id) ? pressureSpecCreate : pressureSpecUpdate;
                     
                     if (!Object.isExtensible(ps)) {
                        ps = { ...ps };
                     }

                     if (!ps.hasOwnProperty('testTypeId') && ps.hasOwnProperty('testType')) {
                        ps['testTypeId'] = ps.testType.id;
                     }
                     if (!ps.hasOwnProperty('testSystemPressureId') && ps.hasOwnProperty('testSystemPressure')) {
                        ps['testSystemPressureId'] = ps.testSystemPressure.id;
                     }
          
                     try {
                         await mutate({ variables: { ...ps } });
                     } catch (error) {
                         console.error("Error during mutation: ", error);
                     }
                 }
     
                 for (const deletedPressureSpec of deletedPressureSpect) {
                     await pressureSpecDelete({ variables: { id: deletedPressureSpec.id } });
                 }
     
                 // Reset change state
                 setIsChanged(false);
             }
         },
     }), [pressureSpecs, deletedPressureSpect, isChanged, pressureSpecCreate, pressureSpecUpdate, pressureSpecDelete]);
     
      useEffect(() => {
         if (dataTestTypes?.testTypes) {
            setTestTypes(dataTestTypes?.testTypes);
         }

         if (dataTestSystemPressures?.testSystemPressures) {
            setTestSystemPressures(dataTestSystemPressures?.testSystemPressures);
         }

         if (data?.pressureSpecs) {
            setPressureSpecs(data?.pressureSpecs);
         }
      }, [data, dataTestTypes, dataTestSystemPressures]);

      /**
       * Adds a pressure spec to the list.
       */
      const handleAddPressureSpec = () => {
         const pressureSpec = { id: uniqueId('new'), operatorId };
         setPressureSpec(pressureSpec);
         setShowEdit(true);
         setIsChanged(true);
         onChange && onChange();
      };

      /**
       * Edit a pressure spec.
       */
      const handleEditPressureSpec = () => {
         setShowEdit(true);
      };

      /**
       * Delete the pressure spec.
       */
      const handleDeletePressureSpec = () => {
         setIsChanged(true);
         if (!isString(pressureSpecValue.id)) {
            const cacheDeleted = clone(deletedPressureSpect);
            cacheDeleted.push(pressureSpecValue);
            setDeletedPressureSpecs(cacheDeleted);
         }

         const index = findIndex(pressureSpecs, { id: pressureSpecValue.id });

         if (index >= 0) {
            const cache = [...pressureSpecs];
            removeOne(cache, index);
            setPressureSpecs(cache);
            onChange && onChange();
         } else {
            console.log('Could not find pressure spec to delete', pressureSpecValue);
         }
      }

      /**
       * Handle selecting an pressure spec from the table.
       * @param pressureSpec The pressureSpec selected
       * @param isDoubleClick Indicates if the selection was a double click.
       */
      const handleSelect = (pressureSpec, isDoubleClick = false) => {
         setPressureSpec(pressureSpec);
         setPressureSpecId(pressureSpec?.id);
         setShowEdit(isDoubleClick);
      };

      /**
       * Get the columns for the pressureSpec table.
       */
      const columns = useMemo(
         () => [
            {
               Header: 'Test Type',
               accessor: 'testType.testType',
            },
            {
                Header: 'System Pressure',
                accessor: 'testSystemPressure.pressureText',
             },
             {
                Header: 'Min. Allowable Pressure',
                accessor: 'minimumAllowablePressure',
             },
             {
                Header: 'Max. Allowable Pressure',
                accessor: 'maximumAllowablePressure',
             },
             {
                Header: 'Max. On Test Pressure',
                accessor: 'maximumOnTestPressure',
             },
             {
                Header: 'Max. Allowable Pressure Drop',
                accessor: 'maximumAllowablePressureDrop',
             },
         ], []
      );

      /**
       * When the user submits changes to the selected pressureSpec, update the list and hide the dialog.
       * @type {(function(*): void)|*}
       */
      const handleSubmit = useCallback((pressureSpec) => {
        console.log("PressureSpecTable handle submit");
        const cache = [...pressureSpecs]; // Create a shallow copy of the array
        const index = findIndex(cache, { id: pressureSpec.id });
        console.log("Index " + index);
        if (index < 0) {
            // If the item doesn't exist, add it
            console.log("PressureSpecs adding to cache " + JSON.stringify(pressureSpec));
            setPressureSpecs([...cache, pressureSpec]);
        } else {
            // If the item exists, update it
            console.log("Updating cache");
            const updatedCache = [...cache]; // Create a new copy for updates
            updatedCache[index] = pressureSpec; // Update the specific index
            console.log("PressureSpecs cache " + JSON.stringify(updatedCache));
            setPressureSpecs(updatedCache); // Set the new array to state
        }
        console.log("PressureSpecs cache " + JSON.stringify(cache));
         setPressureSpecId(pressureSpecValue?.id);
         setPressureSpec(pressureSpec);
         setShowEdit(false);
         setIsChanged(true);
         onChange && onChange();
      }, [pressureSpecValue, pressureSpecs]);

      /**
       * On close, close the edit dialog.
       */
      const handleClose = () => {
         setShowEdit(false);
      };

      return (
         <>
            <DataLoadingAndErrors error={error} isLoading={loading} />
            <ItemTable titleKey={'operator.pressureSpecs.label'} columns={columns} items={pressureSpecs}
               onSelect={handleSelect}
               selectId={pressureSpecId}>
               <Button color='primary' onClick={handleAddPressureSpec} style={{ marginLeft: 8 }}>
                  <FHGTypography noWrap id={'operator.addTestLength.button'} />
               </Button>
               <Button color='primary' onClick={handleEditPressureSpec} style={{ marginLeft: 8 }} disabled={!pressureSpecId}>
                  <FHGTypography noWrap id={'operator.editTestLength.button'} disabled={!pressureSpecId} />
               </Button>
               <Button color='primary' onClick={handleDeletePressureSpec} style={{ marginLeft: 8 }} disabled={!pressureSpecId}>
                  <FHGTypography noWrap id={'operator.deleteTestLength.button'} disabled={!pressureSpecId} />
               </Button>
            </ItemTable>
            {showEdit && (
               <PressureSpecEdit pressureSpecValue={pressureSpecValue} testTypes={testTypes} testSystemPressures={testSystemPressures} onSubmit={handleSubmit} onClose={handleClose} />
            )}
         </>
      );
   });

export default PressureSpecTable;
