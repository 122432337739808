import InputAdornment from '@mui/material/InputAdornment';
import {makeStyles} from '@mui/styles'
import { alpha } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search'
import PropTypes from 'prop-types'
import React, {useState} from 'react'
import {useIntl} from 'react-intl';
import TextField from '../../../components/TextField';
import {formatMessage} from '../../utils/Utils';

const useStyles = makeStyles(theme => ({
   searchStyle: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
         backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      width: '100%',
      [theme.breakpoints.up('sm')]: {
         marginLeft: theme.spacing(3),
         width: 'auto',
      },
   },
   inputRoot: {
      width: '100%',
   },
   '::placeholder': {
      color: '#1796a4 !important',
   },
   textFieldStyle: {
      marginTop: 0,
      '& input': {
         transition: theme.transitions.create('width'),
         [theme.breakpoints.up('sm')]: {
            width: 120,
            '&:focus': {
               width: 200,
            },
         },
         padding: 8,
      }
   },
   inputAdornmentStyle: {
      backgroundColor: theme.palette.primary.main,
      width: 46,
      height: 36,
      maxHeight: 'unset',
   },
   iconStyle: {
      color:  theme.palette.primary.contrastText,
      margin: 'auto',
   },
}), {name: "searchFilterStyles"});

/**
 * The search header for a TableFHG.
 *
 * Reviewed: 3/26/20
 *
 * @param globalFilter The current global filter for the table.
 * @param setGlobalFilter The callback when the global filter changes.
 * @param placeholder The placeholder text for the search.
 * @return {*}
 * @constructor
 */
export default function SearchFilter({globalFilter, setGlobalFilter, placeholder, showSearchTextField = true}) {
   const classes = useStyles();
   const [showClose, setShowClose] = useState(false);
   const [shouldShowSearch, setShouldHideSearch] = useState(showSearchTextField);
   const intl = useIntl();

   /**
    * Handle the changes to the search TextField.
    * @param e The change event.
    */
   const handleChange = (e) => {
      setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
   };

   /**
    * Handle the cancel of the search.
    */
   const handleCancelSearch = () => {
      setGlobalFilter(undefined);
   };

   return (
      <div className={classes.searchStyle}>
          {shouldShowSearch && (
         <TextField
            name={'search'}
            defaultValue={''}
            value={globalFilter}
            className={classes.textFieldStyle}
            // inputProps={{onKeyDown: this.onKeyDown}}
            onChange={handleChange}
            placeholder={placeholder || formatMessage(intl, 'search.placeholder', 'Search…')}
            classes={{
               root: classes.inputRoot,
            }}
            InputProps={{
               'aria-label': 'Search',
               style: {paddingRight: 0},
               className: classes.textFieldStyle,
               onFocus: () => setShowClose(true),
               onBlur: () => setShowClose(false),
               endAdornment: (
                  <InputAdornment position='end' className={classes.inputAdornmentStyle}>
                     {showClose ?
                        <CloseIcon className={classes.iconStyle} onMouseDown={handleCancelSearch}/> :
                        <SearchIcon className={classes.iconStyle}/>
                     }
                  </InputAdornment>
               )
            }}
         />
          )}
      </div>
   )
};

SearchFilter.propTypes = {
   globalFilter: PropTypes.string,
   setGlobalFilter: PropTypes.func.isRequired,
   placeholder: PropTypes.string,
};
