import { useCallback } from 'react';
import React, { useState } from 'react';
import FHGTextField from '../../components/TextField';
import ModalDialog from '../../fhg/components/dialog/ModalDialog';
import useEditData from '../../fhg/components/edit/useEditData';

import { Select, MenuItem, InputLabel } from '@mui/material';
import { forEach } from 'lodash';
// import { Select, MenuItem } from '@fhg/components';

/**
 * Component to edit a single pressure spec.
 *
 * @param pressureSpecValue The pressure spec to edit.
 * @param onSubmit Submit the changes to the pressure spec.
 * @param onClose Close the edit dialog.
 * @return {JSX.Element}
 * @constructor
 */
export default function PressureSpecEdit({ pressureSpecValue: pressureSpecValue, testTypes, testSystemPressures, onSubmit, onClose }) {

   const [editValues, handleChange, { isChanged, getValue, defaultValues }] = useEditData(pressureSpecValue);

   /**
    * Submit the changes to the pressure spec.
    * @type {(function(*): void)|*}
    */
   const handleSubmit = useCallback((event) => {

      console.log("handleSubmit " + JSON.stringify(editValues));

      if (event) {
         event.preventDefault();
         event.stopPropagation();
      }

      if (isChanged) {
         onSubmit && onSubmit({ ...defaultValues, ...editValues });
      }
   }, [editValues, defaultValues, isChanged, onSubmit]);


   const existingTestType = getValue('testType');
   const optionsTestTypes = [];
   var defaultTestTypeValue = '';

   if (existingTestType != null) {
      optionsTestTypes.push({ value: existingTestType.id, label: existingTestType.testType });
      defaultTestTypeValue = existingTestType.id;
   } else {
      for (const element of testTypes) {
         optionsTestTypes.push({ value: element.id, label: element.testType })
      }
      if (testTypes.length == 1) {
         defaultTestTypeValue = testTypes[0].id;
         editValues["testType"] = testTypes[0];
         editValues["testTypeId"] = testTypes[0].id;
      }
   }

   const [selectedTestTypeValue, setSelectedTestTypeValue] = useState(defaultTestTypeValue);

   const handleChangeTestTypeSelect = (event) => {
      setSelectedTestTypeValue(event.target.value);
      const item = testTypes.find(item => item.id === event.target.value);
      editValues["testType"] = item;
      editValues["testTypeId"] = item.id;
   };



   const existingTestSystemPressure = getValue('testSystemPressure');
   const optionsTestSystemPressures = [];
   var defaultTestSystemPressureValue = '';

   if (existingTestSystemPressure != null) {
      optionsTestSystemPressures.push({ value: existingTestSystemPressure.id, label: existingTestSystemPressure.pressureText });
      defaultTestSystemPressureValue = existingTestSystemPressure.id;
   } else {
      for (const element of testSystemPressures) {
         optionsTestSystemPressures.push({ value: element.id, label: element.pressureText })
      }
      if (testSystemPressures.length == 1) {
         defaultTestSystemPressureValue = testSystemPressures[0].id;
         editValues["testSystemPressure"] = testSystemPressures[0];
         editValues["testSystemPressureId"] = testSystemPressures[0].id;
      }
   }

   const [selectedTestSystemPressureValue, setSelectedTestSystemPressureValue] = useState(defaultTestSystemPressureValue);

   const handleChangeTestSystemPressureSelect = (event) => {
      setSelectedTestSystemPressureValue(event.target.value);
      const item = testSystemPressures.find(item => item.id === event.target.value);
      editValues["testSystemPressure"] = item;
      editValues["testSystemPressureId"] = item.id;
   };

   return (
      <ModalDialog open={true} titleKey={'operator.testLengthEdit.title'} onSubmit={(event) => handleSubmit(event)} maxWidth={'sm'}
         fullWidth onClose={onClose}>
         <FHGTextField name='minimumAllowablePressure' type='number' autoFocus label={'Minimum Allowable Pressure'}
            style={{ marginBottom: 8 }}
            onChange={handleChange} value={getValue('minimumAllowablePressure')} required />
         <FHGTextField name='maximumAllowablePressure' type='number' label={'Maximum Allowable Pressure'} style={{ marginBottom: 8 }}
            onChange={handleChange} value={getValue('maximumAllowablePressure')} required />
         <FHGTextField name='maximumOnTestPressure' type='number' label={'Maximum On Test Pressure'} onChange={handleChange}
            value={getValue('maximumOnTestPressure')} required />
        <FHGTextField name='maximumAllowablePressureDrop' type='number' label={'MaximumAllowable Pressure Drop'} onChange={handleChange}
            value={getValue('maximumAllowablePressureDrop')} required />

         <InputLabel id="select-label" style={ {color: 'black', fontWeight: '500', marginTop: '20px', marginBottom: '10px', fontSize: '16px' } }>Test Type *</InputLabel>
         <Select
            labelId="select-label"
            name='testTypeId'
            value={selectedTestTypeValue}
            required
            onChange={handleChangeTestTypeSelect}>
            <MenuItem value="" disabled>Select an option</MenuItem>
            {optionsTestTypes.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                  {option.label}
               </MenuItem>
            ))}
         </Select>

         <InputLabel id="select-systemPressure-label" style={ {color: 'black', fontWeight: '500', marginTop: '20px', marginBottom: '10px', fontSize: '16px' } }>Test System Pressure *</InputLabel>
         <Select
            labelId="select-systemPressure-label"
            name='testSystemPressureId'
            value={selectedTestSystemPressureValue}
            required
            onChange={handleChangeTestSystemPressureSelect}>
            <MenuItem value="" disabled>Select an option</MenuItem>
            {optionsTestSystemPressures.map((option) => (
               <MenuItem key={option.value} value={option.value}>
                  {option.label}
               </MenuItem>
            ))}
         </Select>

      </ModalDialog>
   );
}
